@layer base {
  .btn-dark {
    @apply inline-flex items-center py-2 px-4 justify-center border border-transparent shadow-sm font-medium rounded-md text-light bg-dark-500 hover:bg-dark-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-dark-400 mr-1 disabled:bg-dark-300

  }
  .btn-light {
    @apply inline-flex items-center py-2 px-4 justify-center border border-light-500 shadow-sm font-medium rounded-md text-dark bg-light-500 hover:bg-light-700 hover:text-dark-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-light-500 mr-1 disabled:bg-gray-200
  }
  .btn-accent {
    @apply inline-flex items-center py-2 px-4 justify-center border border-accent shadow-sm font-medium rounded-md text-accent-50 bg-accent-500 hover:bg-accent-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-accent-400 mr-1 disabled:bg-accent-300
  }
  .btn-clear {
    @apply inline-flex items-center py-2 px-4 justify-center border border-transparent shadow-none font-medium rounded-md text-dark hover:text-accent bg-transparent hover:bg-transparent focus:outline-none focus:ring-0 mr-1 disabled:text-gray-200
  }
  .btn-gray {
    @apply inline-flex items-center py-2 px-4 justify-center border border-gray-300 shadow-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-400 mr-1 disabled:bg-gray-100
  }
  .btn-red {
    @apply inline-flex items-center py-2 px-4 justify-center border border-transparent shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-red-400 mr-1 disabled:bg-red-300
  }
  .btn-green {
    @apply inline-flex items-center py-2 px-4 justify-center border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-400 mr-1 disabled:bg-green-300
  }
  .btn-sm {
    @apply py-1 px-3 text-sm
  }
  .btn-xs {
    @apply py-0.5 px-2 text-xs min-h-6
  }
  .btn-borderless {
    @apply border-0
  }
  .btn-round {
    @apply rounded-full px-5
  }
  .btn-l {
    @apply rounded-r-none mr-0
  }
  .btn-c {
    @apply rounded-r-none rounded-l-none mr-0 ml-0
  }
  .btn-r {
    @apply rounded-l-none mr-0 ml-0
  }

}

.text-xxs {
  font-size: 0.5rem; line-height: .75rem;
}
