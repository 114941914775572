/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import '../../_library/shared/_styles/modal.scss';
@import '../../_library/shared/_styles/fade-in.scss';
@import '../../_library/shared/_styles/buttons.scss';
@import '../../_library/shared/_styles/headers.scss';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

body {
  margin: 0px;
  font-family: "Ubuntu", sans-serif;
  background-color: #F6F5F2FF;
  color: rgb(48,56,31);
}

.Ubuntu {
  font-family: "Ubuntu", serif !important;
}
